<template>
  <cs-page-user class="views-user-ocrService">
    <cs-panel-default
      title="OCR服务"
      class="d-flex flex-column"
      icon="icon-dingdanliebiao"
    >
    <template v-if="ocrData.isSubscribeBillService ===null || ocrData.isSubscribeBillService === true">
      <div class="ocr-times d-flex align-items-center justify-content-between">
      <div class="ocr-wrap d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <cs-icon
            name="icon-fapiaoshibie1"
            type="symbol"
            style="margin-right: 8px;"
            size="26"
          />
          <b>发票识别 </b>
          剩余次数
          <span>{{ocrData.recogBalance}}</span>
        </div>
      </div>
      <div class="ocr-wrap d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <cs-icon
          name="icon-fapiaoyanzhen1"
          type="symbol"
          style="margin-right: 8px;"
          size="26"
        />
        <b>发票验真</b>
        剩余次数
        <span>{{ocrData.verifyBalance}}</span>
        </div>
      </div>
    </div>
    <cs-panel title="服务明细" style="font-size: 18px;">
      <div class="d-flex justify-content-between" style="margin-bottom: 20px;">
        <div>
          <cs-range-picker
            :start.sync="formModel.startTime"
            :end.sync="formModel.endTime"
            class="right-search-picker"
            style="margin-right: 10px;width: 280px;"
          />
          <a-button type="primary" @click="okClick">确定</a-button>
        </div>
        <div class="d-flex">
          <div class="bg-green c-green right-text"><span style="font-size: 18px;color:#00b3a5;">识别 </span>新增 {{ocrData.recogAdd || 0}}; 使用 {{ocrData.recogUse || 0}}</div>
          <div class="bg-blue c-blue right-text"><span style="font-size: 18px;color: #0794FF;">验真 </span> 新增 {{ocrData.verifyAdd || 0}}; 使用 {{ocrData.verifyUse || 0}}</div>
        </div>
      </div>
    </cs-panel>
    <cs-table
        header-cell-class-name="cs-table-header-cell"
        :resizable="false"
        :stripe="true"
        size="medium"
        align="center"
        ref="CsTable"
        border="none"
        :load="loadData"
      >
        <vxe-table-column
          field="time"
          title="发生时间"
        ></vxe-table-column>
        <vxe-table-column
          field="serviceName"
          title="服务名称"
        ></vxe-table-column>
        <vxe-table-column field="payType" title="类型">
          <template v-slot="{ row }">
            {{ row.businessType === 1 ? '使用' : '增加' }}
          </template>
        </vxe-table-column>
        <vxe-table-column field="alterationOriginNum" title="次数">
        </vxe-table-column>
        <template #empty>
           <span style="color: #4f4f4f;font-size:16px;">
            <img src="../../../public/img/开通未购买服务@1x.png">
            <p style="margin-top:28px;">暂无明细</p>
          </span>
          </template>
      </cs-table>
    </template>
    <template v-if="ocrData.isSubscribeBillService === false">
      <span style="color:#4f4f4f;height:100%" class="d-flex flex-column align-items-center justify-content-center">
        <img src="../../../public/img/off-service.png" style="width: 200px;height: 200px;">
        <p style="margin-top: 10px;">您还未开通服务哟~</p>
      </span>
    </template>
    </cs-panel-default>
  </cs-page-user>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      formModel: {
        startTime: moment(new Date()).subtract(6, 'days').format('YYYY-MM-DD'),
        endTime: moment(new Date()).format('YYYY-MM-DD')
      },
      ocrData: {
        recogBalance: 0,
        verifyBalance: 0,
        recogAdd: 0,
        recogUse: 0,
        verifyAdd: 0,
        verifyUse: 0,
        isSubscribeBillService: null
      },
    };
  },
  created(){
  },
  methods: {
    async loadData({ page, size, condition }) {
      const res = await this.api.service["/api/portal/ucs-tenant/bill/get-bill-info"](
        {
          pageNo: page,
          pageSize: size,
          ...this.formModel,
          ...condition
        }, {})
      await this.api.toast(res, 0);
      const { data } = res
      if (!data) { 
        this.ocrData.isSubscribeBillService = false
        return
      }
      this.ocrData.isSubscribeBillService = data.isSubscribeBillService
      if(!data.billList) {
        return
      }
      this.ocrData.recogBalance = data.recogBalance
      this.ocrData.verifyBalance = data.verifyBalance
      this.ocrData.recogAdd = data.recogAdd
      this.ocrData.recogUse = data.recogUse
      this.ocrData.verifyAdd = data.verifyAdd
      this.ocrData.verifyUse = data.verifyUse
      const { records, total } = data.billList
        return {
          data: records,
          total,
        };
    },
    okClick() {
      this.$refs.CsTable.search(this.formModel);
    }  
  },
};
</script>

<style lang="less" scoped>
.views-user-ocrService {
  /deep/ .cs-panel-content {
    padding: 20px;
    height: calc(100vh - 415px);
    min-height: 480px;
  }
  /deep/ .cs-panel-header {
    .ant-divider-vertical {
      height: 18px;
    }
  }
  .ocr-times {
    .ocr-wrap {
      flex: 1;
      background: rgba(0,164,151,0.03) url(../../../public/img/发票识别背景@2x.png) no-repeat right / 56% 66px;
      border-radius: 8px;
      padding: 0 0 0 30px;
      margin-right: 20px;
      height: 66px;
      line-height: 20px;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #757878;
      z-index: 1;
      &:last-child {
        margin-right: 0;
      }
      b {
        color: #202121;
        font-weight: 500;
        margin-right: 2px;
      }
      span {
        font-family: DINAlternate-Bold;
        font-size: 30px;
        font-weight: 700;
        color: #202121;
        margin-left: 8px;
      }
    }
  }
  .bg-green {
    background: #F6FCFB;
  }
  .bg-blue {
    background: #F4FCFF;
  }
  .c-blue {
    color: #0096FF;
  }
  .c-green {
    color: #00B7A5;
  }
  .right-text {
    font-size: 14px;
    font-family: PingFangSC-Regular;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    &:last-child {
      margin-left: 10px;
    }
    span {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

</style>
